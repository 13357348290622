<template>
    <div>
        <b-row class="match-height">
            <b-col
                lg="12"
                md="12"
                v-for="(cashier, cashiers) in cashiers"
                :key="cashier.cashierId"
            >
                <b-card>
                    <b-card-title class="card-header">
                        <h4>{{ cashier.name }}</h4>
                        <b-button variant="primary" @click="getCashiers()">
                            <feather-icon icon="RefreshCwIcon" />
                        </b-button>
                    </b-card-title>
                    <h6>Dinero Total: ${{ cashier.total }}</h6>
                    <h6>Ultima actualizacion: {{ new Date(cashier.modifiedAt) | moment("DD/MM/YYYY, h:mm a") }}</h6>
                    <b-overlay :show="overlayStatus" rounded="sm">
                        <b-table
                            hover
                            responsive
                            :items="cashier.cashStock"
                            :per-page="perPagePointsList"
                            :current-page="currentPagePointsList"
                            ref="refInvoiceListTable"
                            :fields="tableColumnsPointsList"
                            primary-key="id"
                            show-empty
                            empty-text="No hay registros"
                            class="position-relative"
                        >
                            <template #cell(denomination)="data"> ${{ data.value }} </template>
                            <template #cell(quantity)="data"> {{ data.value }} </template>
                            <template #cell(dispensed)="data"> {{ data.value }} </template>
                            <template #cell(rejected)="data"> {{ data.value }} </template>
                            <template #cell(subtotal)="data"> ${{ data.value }} </template>
                        </b-table>
                    </b-overlay>
                    <!-- Pagination -->
                    <div class="mx-2 mb-2">
                        <b-row>
                            <!-- Pagination -->
                            <b-col
                                cols="12"
                                sm="12"
                                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                            >
                                <b-pagination
                                    v-model="currentPagePointsList"
                                    :total-rows="totalRowsPointsList"
                                    :per-page="perPagePointsList"
                                    first-number
                                    last-number
                                    class="mb-0 mt-1 mt-sm-0"
                                    prev-class="prev-item"
                                    next-class="next-item"
                                >
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                </b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </b-card>
            </b-col>

        </b-row>
    </div>


</template>


<style lang="scss" scoped>
@import 'cashierBalances.css';
</style>

<style lang="scss" scoped>
.scroll-area {
  height: 10.5em;
}
</style>

<script>
import {
    BCard, BCardText, BCardBody, BCardTitle, BCardSubTitle,
    BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm,
    BButton, BAlert, BTable, BOverlay, VBTooltip, BPagination, BTooltip,
    BCardHeader, BMedia, BMediaAside, BAvatar, BMediaBody, BBadge
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import data from '@/router/routes/ui-elements'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'


import store from '@/store'



export default {
    components: {
        BAlert,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BCard,
        BCardText,
        BButton,
        BCardBody,
        BCardTitle,
        BCardSubTitle,
        vSelect,
        BTable,
        BOverlay,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,
        BPagination,
        BTooltip,

        BBadge,
        BCardHeader,
        BMedia,
        BMediaAside,
        BAvatar,
        BMediaBody,

        // 3rd party
        VuePerfectScrollbar,
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    data() {
        return {
            overlayStatus: true,
            cashiers: [],
            perfectScrollbarSettings: {
                maxScrollbarLength: 100,
                wheelPropagation: true,
            },
            perPagePointsList: 10,
            totalRowsPointsList: 1,
            currentPagePointsList: 1,
            tableColumnsPointsList: [
                { key: "denomination", label: "Denominacion", sortable: true },
                { key: "quantity", label: "Cantidad", sortable: true },
                { key: "dispensed", label: "Dispensado", sortable: true },
                { key: "rejected", label: "Rechazado", sortable: true },
                { key: "subtotal", label: "Subtotal", sortable: true },
            ],
        }
    },
    created() {
        this.getCashiers();
        this.timer = setInterval(this.getCashiers, 60000);  // Cada 5 minutos
    },
    beforeRouteLeave(to, from, next) {
        clearInterval(this.timer);
        next();
    },
    methods: {
        getCashiers() {
            const user = JSON.parse(localStorage.getItem("user"))

            this.overlayStatus = true;

            this.$axios
            .post(this.$services + "manager/findCashiersApi", {
                companyId: user.companyId,
            })
            .then(res => {
                if (!res.data.error) {
                    this.cashiers = res.data;
                }
            }).finally(() => {
                this.overlayStatus = false;
            });
        },
    },
}
</script>
